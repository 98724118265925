import React, { useState } from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination } from 'swiper/modules';

// Hook Imports
import useWindowDimensions from '../hooks/useWindowDimensions';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';

// Component Imports
import TestimonialSlide from './testimonialSlide';
import LogoPagination from './logoPagination';

// Styled Components
const SwiperStylesOveride = styled.div`
  .swiper {
    /* border: 1px solid green; */
    overflow: visible;
  }

  .swiper-wrapper {
    margin-bottom: 24px;

    @media (min-width: 1000px) {
      margin-bottom: 48px;
    }

    @media (min-width: 1200px) {
      margin-bottom: 64px;
    }
  }

  .swiper-pagination-bullets.swiper-pagination-horizontal {
    bottom: -32px;
    font-size: 0px; // Removes extra height from pagination block
  }
`;

const TestimonialCarousel = ({ testimonials, hasLogoPagination }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const windowWidth = useWindowDimensions().width;

  let clients = testimonials.map(({ client, ...rest }) => client);
  return (
    <>
      <SwiperStylesOveride>
        <Swiper
          key={windowWidth}
          modules={[Autoplay, Pagination]}
          pagination={
            hasLogoPagination && windowWidth >= 1000 ? '' : { clickable: true }
          }
          autoplay={{
            delay: 7500,
            disableOnInteraction: true,
          }}
          loop={true}
          grabCursor={true}
          className='swiper'
          onSlideChange={(swiper) => {
            setActiveIndex(swiper.realIndex);
          }}
        >
          {testimonials.map((testimonial, index) => (
            <SwiperSlide key={index}>
              <TestimonialSlide testimonial={testimonial} />
            </SwiperSlide>
          ))}
          {hasLogoPagination && (
            <LogoPagination
              clients={clients}
              activeIndex={activeIndex}
              windowWidth={windowWidth}
            />
          )}
        </Swiper>
      </SwiperStylesOveride>
    </>
  );
};

TestimonialCarousel.propTypes = {
  testimonials: propTypes.array,
  hasLogoPagination: propTypes.bool,
};

export default TestimonialCarousel;
