import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

// Styled Components
const ToggleContainer = styled.div`
  position: relative;
`;

const ToggleBackground = styled.div`
  display: flex;
  gap: 8px;
  padding: 0px 12px;
  flex-direction: row;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 40px;
`;

const IconWrapper = styled.a`
  cursor: pointer;
  padding: 12px 12px;
  display: flex;
  /* border: 1px solid red; */
`;

const IconSVG = styled.svg`
  width: 16px;
  height: 16px;
  stroke: var(--blue6);
  fill: none;
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
  box-sizing: border-box;
`;

const FocusedIconsContainer = styled(ToggleBackground)`
  background: none;
`;

const Indicator = styled.div`
  --dx: ${({ translateAmount }) => translateAmount};
  --transition: transform 0.5s cubic-bezier(0.215, 0.61, 0.355, 1);

  width: 64px;
  height: 40px;
  border-radius: 40px;
  background: var(--blue6);
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  transition: var(--transition);

  transform: translateX(var(--dx));

  ${FocusedIconsContainer} {
    transform: translateX(calc(-1 * var(--dx)));
    transition: var(--transition);
  }

  svg {
    stroke: var(--blue3);
  }
`;

const DeviceToggle = ({ deviceType, setDeviceType }) => {
  const [translateAmount, setTranslateAmount] = useState('0px');

  //* Every time deviceType changes, update translate amount
  useEffect(() => {
    switch (deviceType) {
      case 'TV':
        setTranslateAmount('0px');
        break;
      case 'Online':
        setTranslateAmount('48px');
        break;
      case 'Mobile':
        setTranslateAmount('96px');
        break;
    }
  }, [deviceType]);

  return (
    <ToggleContainer>
      <ToggleBackground>
        <IconWrapper onClick={() => setDeviceType('TV')}>
          <IconSVG>
            <use href={`/icons/feather-sprite.svg#tv`} />
          </IconSVG>
        </IconWrapper>
        <IconWrapper onClick={() => setDeviceType('Online')}>
          <IconSVG>
            <use href={`/icons/feather-sprite.svg#youtube`} />
          </IconSVG>
        </IconWrapper>
        <IconWrapper onClick={() => setDeviceType('Mobile')}>
          <IconSVG>
            <use href={`/icons/feather-sprite.svg#smartphone`} />
          </IconSVG>
        </IconWrapper>
      </ToggleBackground>
      <Indicator translateAmount={translateAmount}>
        <FocusedIconsContainer>
          <IconWrapper>
            <IconSVG>
              <use href={`/icons/feather-sprite.svg#tv`} />
            </IconSVG>
          </IconWrapper>
          <IconWrapper>
            <IconSVG>
              <use href={`/icons/feather-sprite.svg#youtube`} />
            </IconSVG>
          </IconWrapper>
          <IconWrapper>
            <IconSVG>
              <use href={`/icons/feather-sprite.svg#smartphone`} />
            </IconSVG>
          </IconWrapper>
        </FocusedIconsContainer>
      </Indicator>
    </ToggleContainer>
  );
};

export default DeviceToggle;
