import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import { useSwiper } from 'swiper/react';

const Center = styled.div`
  display: grid;
  place-content: center;
  margin-bottom: calc(24px + var(--swiper-pagination-bullet-size));
`;

const ClientLogos = styled.div`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-template-columns: ${({ numClients }) =>
    'repeat(' + numClients + ', 1fr);'};
  gap: 48px;

  overflow: hidden;

  @media (min-width: 1200px) {
    gap: 64px;
  }
`;

const AspectRatioWrapper = styled.div`
  // Keeps logos constrained to a 16:9 ratio
  height: 0;
  padding-top: 56.25%;
  position: relative;
  width: 113.79px;

  @media (min-width: 1000px) {
    // Sets height of 56px
    width: 99.56px;
  }

  @media (min-width: 1200px) {
    // Sets height of 64px
    width: 113.79px;
  }
`;

const AspectRatioContents = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;

const ClientLogo = styled.svg`
  width: 100%;
  height: 100%;
  fill: var(--logo-fill, var(--blue3));

  @media (min-width: 1000px) {
    fill: ${({ currentLogo }) =>
      currentLogo ? 'var(--logo-fill-active)' : 'var(--logo-fill)'};
    // TODO: Give this a curve
    transition: all 0.3s ease-in-out;
    cursor: pointer;

    &:hover {
      fill: var(--logo-fill-active);
    }
  }
`;

const LogoPagination = ({ clients, activeIndex, windowWidth }) => {
  const swiper = useSwiper();
  return (
    <Center>
      {windowWidth >= 1000 ? (
        <ClientLogos numClients={clients.length}>
          {clients.map((client, index) => (
            <AspectRatioWrapper key={client.company}>
              <AspectRatioContents>
                <ClientLogo
                  dangerouslySetInnerHTML={{ __html: client.svgLogo }}
                  onClick={() => swiper.slideTo(index)}
                  currentLogo={index === activeIndex}
                  alt={`${client.company}-logo`}
                />
              </AspectRatioContents>
            </AspectRatioWrapper>
          ))}
        </ClientLogos>
      ) : (
        <AspectRatioWrapper key={clients[activeIndex].company}>
          <AspectRatioContents>
            <ClientLogo
              dangerouslySetInnerHTML={{ __html: clients[activeIndex].svgLogo }}
              key={clients[activeIndex].company}
            />
          </AspectRatioContents>
        </AspectRatioWrapper>
      )}
    </Center>
  );
};

LogoPagination.propTypes = {
  clients: propTypes.array,
};

export default LogoPagination;
