import React, { useState, useRef } from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';

//Component Imports
import { TwoCols, TwoCol, Section } from '../components/page-elements';
import SEO from '../components/seo';
import Container from '../components/container';
import SuperHeaderBlock from '../components/superHeaderBlock';
import AnimatedStripe from '../components/animatedStripe';
import BottomCut from '../components/bottomCut';
import Button from '../components/buttons';
import CenteredTextBlock from '../components/centeredTextBlock';
import LogoGrid from '../components/logoGrid';
import Spacer from '../components/spacer';
import PageTextBlock from '../components/pageTextBlock';
import VideoGrid from '../components/videoGrid';
import DeviceSwitcher from '../components/device-switcher/deviceSwitcher';
import FourPointBlock from '../components/fourPointBlock';
import TestimonialCarousel from '../components/testimonialCarousel';
import HalfScreenVideo from '../components/halfScreenVideo';
import CTABlock from '../components/ctaBlock';

// Asset Imports
import Stripes01SVG from '../images/stripes/stripes01.inline.svg';
import Stripes04SVG from '../images/stripes/stripes04.inline.svg';
import ShapesAndSizes from '../images/shapes-sizes.inline.svg';

// Function Imports
import shuffleArray from '../helpers/shuffleArray';

// Styled Components
const HeroSection = styled(Section)`
  background-color: rgba(8, 39, 58, 0.6);
  overflow: hidden;
`;

const HeroVideo = styled.video`
  min-width: 100%;
  min-height: 100%;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: -1;
`;

const HeroStripes = styled(AnimatedStripe)`
  position: absolute;
  height: 60%;
  width: 25%;
  right: -150px;
  bottom: -50px;
  z-index: 99;
  display: none;

  @media (min-width: 1024px) {
    display: block;
  }
`;

const SectionGraphicContainer = styled.div`
  position: relative;
  margin: 0 auto;
  width: 100vw;
  max-width: 275px;

  @media (min-width: 37.5em) {
    max-width: 400px;
  }
  @media (min-width: 64em) {
    width: 50vw;
    margin-top: -30px;
    max-width: none;
    transform: rotate(-7.25deg);
    padding-left: ${({ theme }) => theme.spacing.xxl};
  }
`;

const StyledShapesAndSizes = styled(ShapesAndSizes)`
  width: 100%;
  height: 100%;
  max-height: 500px;
`;

const ClientsSection = styled(Section)`
  overflow: hidden;
`;

const Center = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

const PortfolioStripes = styled(AnimatedStripe)`
  position: absolute;
  top: -25%;
  right: -65%;
  width: 150%;
  height: 130%;
  pointer-events: none;
  z-index: 1;

  @media (min-width: 600px) and (max-width: 1120px) {
    top: -35%;
    width: 100%;
  }
`;

const IndexPage = ({ data }) => {
  const { sanityHomepage } = data;
  const heroVideoRef = useRef(null);

  const [isReelOpen, setIsReelOpen] = useState(false);

  const clientLogos = shuffleArray(sanityHomepage.logos);
  const videoForSwitcher = sanityHomepage.shapesAndSizesVideo;

  const pausePageVideos = () => {
    heroVideoRef.current.pause();
  };

  const playPageVideos = () => {
    heroVideoRef.current.play();
  };

  return (
    <>
      <SEO title='Creative Video Agency' />
      <HeroSection dark={true} cuts='bottomCut'>
        <HeroVideo
          ref={heroVideoRef}
          autoPlay
          muted
          loop
          playsInline
          preload='metadata'
          poster={sanityHomepage.heroVideo.poster.asset.url}
        >
          <source
            src={sanityHomepage.heroVideo.webm.asset.url}
            type='video/webm'
          />
          <source
            src={sanityHomepage.heroVideo.mp4.asset.url}
            type='video/mp4'
          />
        </HeroVideo>
        <Container>
          <SuperHeaderBlock
            data={sanityHomepage.hero}
            onReelOpen={pausePageVideos}
            onReelClose={playPageVideos}
          />
        </Container>
        <HeroStripes>
          <Stripes01SVG />
        </HeroStripes>
        <BottomCut />
      </HeroSection>
      <ClientsSection cuts='topAndBottomCut'>
        <Container>
          <CenteredTextBlock data={sanityHomepage.logoBlock} />
          <Spacer size={48} />
          <LogoGrid clients={clientLogos} />
          <Spacer size={24} />
        </Container>
        <BottomCut dark />
      </ClientsSection>
      <Section dark cuts='topCut' style={{ overflowX: 'clip' }}>
        <Container>
          <TwoCols reverse>
            <TwoCol>
              <PageTextBlock data={sanityHomepage.marketingBlock1} />
            </TwoCol>
            <TwoCol>
              <HalfScreenVideo
                data={sanityHomepage.midPageVideo}
                position={'left'}
              />
            </TwoCol>
          </TwoCols>
        </Container>
      </Section>
      <Section dark cuts='extSectBottomCut' style={{ overflowX: 'clip' }}>
        <Container>
          <TwoCols>
            <TwoCol centerVertically>
              <PageTextBlock data={sanityHomepage.marketingBlock2} />
            </TwoCol>
            <TwoCol centerVertically>
              <DeviceSwitcher data={videoForSwitcher} />
            </TwoCol>
          </TwoCols>
        </Container>
        <BottomCut light />
      </Section>
      <Section light cuts='topCut'>
        <FourPointBlock data={sanityHomepage.products} />
      </Section>
      <Section light cuts='extSectBottomCut' style={{ overflowX: 'clip' }}>
        <Container wide>
          <div style={{ position: `relative`, zIndex: 2 }}>
            <CenteredTextBlock data={sanityHomepage.portfolio} />
            <Spacer size={64} />
            <VideoGrid videos={sanityHomepage.featuredWork} />
            <Spacer size={48} />
            <Center>
              <Button
                type='link'
                label='See more work'
                arrow
                path='/portfolio'
              />
            </Center>
          </div>
          <PortfolioStripes>
            <Stripes04SVG />
          </PortfolioStripes>
          <BottomCut />
        </Container>
      </Section>
      <Section cuts='topCut' style={{ overflowX: 'clip' }}>
        <Container>
          <CenteredTextBlock data={sanityHomepage.testimonialsText} />
          <Spacer size={64} />
        </Container>
        {/* TestimonialCarousel is outside of container so swiping elements go to edge of screen. */}
        <TestimonialCarousel
          testimonials={sanityHomepage.testimonials}
          hasLogoPagination={true}
        />
      </Section>
      <Section cuts='bottomCut'>
        <Container>
          <CTABlock data={sanityHomepage.ctaBlock} />
          <BottomCut dark />
        </Container>
      </Section>
    </>
  );
};

// GraphQL Query
export const indexPageQuery = graphql`
  {
    sanityHomepage {
      heroVideo {
        ...SanityVideoLoopFragment
      }
      hero {
        preHeader
        title
        body
        primaryCTA
        primaryCTAData {
          ...SanityButtonFragment
        }
        secondaryCTA
        secondaryCTAData {
          ...SanityButtonFragment
        }
      }
      logoBlock {
        preHeader
        title
        body
        primaryCTA
        primaryCTAData {
          ...SanityButtonFragment
        }
        secondaryCTA
        secondaryCTAData {
          ...SanityButtonFragment
        }
      }
      logos {
        company
        svgLogo
      }
      marketingBlock1 {
        ...SanityPageTextBlockFragment
      }
      midPageVideo {
        ...SanityVideoLoopFragment
      }
      marketingBlock2 {
        ...SanityPageTextBlockFragment
      }
      shapesAndSizesVideo {
        ...SanityVideoLoopFragment
      }
      products {
        preHeader
        title
        miniPoint1 {
          ...SanityMiniPointFragment
        }
        miniPoint2 {
          ...SanityMiniPointFragment
        }
        miniPoint3 {
          ...SanityMiniPointFragment
        }
        miniPoint4 {
          ...SanityMiniPointFragment
        }
        cta
        ctaData {
          ...SanityButtonFragment
        }
      }
      portfolio {
        ...SanityCenteredTextBlockFragment
      }
      featuredWork {
        ...SanityVideoFragment
      }
      testimonialsText {
        ...SanityCenteredTextBlockFragment
      }
      testimonials {
        quoteHighlight
        quote
        author
        jobTitle
        client {
          company
          svgLogo
        }
        image {
          ...SanityImageFragment
        }
      }
      ctaBlock {
        ...SanityCTABlockFragment
      }
    }
  }
`;

export default IndexPage;
