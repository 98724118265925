import React, { useState } from 'react';
import styled from 'styled-components';
import Spacer from '../spacer';
import DeviceToggle from './deviceToggle';

import YouTubeOverlaySVG from '../../images/device-switcher/youtube-overlay.inline.svg';
import InstaStoriesOverlaySVG from '../../images/device-switcher/instagram-stories-overlay.inline.svg';
import InstaPostOverlaySVG from '../../images/device-switcher/instagram-post-overlay.inline.svg';
import FacebookOverlaySVG from '../../images/device-switcher/facebook-overlay.inline.svg';

import { ButtonText } from '../../theme/typography';

//Styled Components
const SwitcherContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  max-width: 100%;
  position: relative;
`;

const DeviceContainer = styled.div`
  --transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  --phoneWidth: clamp(110px, 25vw, 220px);
  --r: calc(var(--phoneWidth) / 7.25);
  --screenPadding: clamp(4px, calc(var(--phoneWidth) / 32.5), 12px);
  --r2: calc(var(--r) - var(--screenPadding));

  position: relative;
  width: var(--phoneWidth);
  aspect-ratio: 290 / 504;

  display: grid;
  place-items: center;
  margin: 0 calc(var(--phoneWidth) + 8px); //To prevent translating devices overlapping other content

  @media (min-width: 1024px) {
    --phoneWidth: clamp(200px, 14vw, 300px);
  }
`;

const TVFoot = styled.div`
  position: absolute;
  bottom: -5%;

  width: 40%;
  height: 10%;

  background: var(--blue2);
  border-radius: 0 0 4px 4px;

  transition-property: transform, opacity;
  transition-delay: ${({ $deviceType }) =>
    $deviceType == 'TV' ? '0.15s, 0.15s' : '0, 0.2s'};
  transition-duration: 0.35s;
  transition-timing-function: var(--transition-timing-function);

  transform: ${({ $deviceType }) =>
    $deviceType == 'TV' ? '' : 'translateY(-50px)'};
  opacity: ${({ $deviceType }) => ($deviceType == 'TV' ? 1 : 0)};
`;

const TVFootLeft = styled(TVFoot)`
  left: -50%;
`;

const TVFootRight = styled(TVFoot)`
  right: -50%;
`;

const Tooltip = styled.span`
  position: absolute;
  will-change: transform, opacity;
  left: 50%;
  bottom: -48px;
  padding: 5px 16px 3px 16px;
  border-radius: 16.5px;

  background: var(--blue6);
  color: var(--blue1);

  pointer-events: none;
  user-select: none;

  transform-origin: top left;
  transform: ${({ $deviceType }) =>
    $deviceType == 'Mobile'
      ? 'translate(-50%, -70px)'
      : 'scaleX(calc(1 / var(--fx))) translate(-50%, -70px);'};

  transition: opacity, transform;
  transition-duration: 0.5s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);

  z-index: -3;
`;

const PrimaryDevice = styled.div`
  --fx: ${({ $deviceType }) => ($deviceType != 'Mobile' ? 3.04 : 1)};
  position: absolute;
  inset: 0;
  z-index: 2;
  background: radial-gradient(
    circle at 0% 15%,
    var(--blue4) 0%,
    var(--blue2) 30%
  );
  border-radius: calc(var(--r) / var(--fx)) / calc(var(--r) / 1);
  transform-origin: center center;
  transform: scaleX(var(--fx));

  transition: all 0.5s var(--transition-timing-function); //TODO

  &::after {
    --padding2: 1px;
    --r3: calc(var(--r) - var(--padding2));

    position: absolute;

    top: var(--padding2);
    right: calc(var(--padding2) / var(--fx));
    bottom: var(--padding2);
    left: calc(var(--padding2) / var(--fx));
    background: var(--blue2);
    border-radius: calc(var(--r3) / var(--fx)) / calc(var(--r3) / 1);
    content: '';

    transition: border-radius 0.5s var(--transition-timing-function);
  }

  &:hover {
    ${Tooltip} {
      opacity: 0.9;
      transform: scaleX(calc(1 / var(--fx))) translate(-50%, 0);
    }
  }
`;

const PrimaryContentContainer = styled.div`
  z-index: 1;
  position: absolute;
  top: var(--screenPadding);
  right: calc(var(--screenPadding) / var(--fx));
  bottom: var(--screenPadding);
  left: calc(var(--screenPadding) / var(--fx));

  background: var(--white);
  border-radius: calc(var(--r2) / var(--fx)) / calc(var(--r2) / 1);
  transition: border-radius 0.5s var(--transition-timing-function); // TODO

  overflow: hidden;
`;

const PrimaryVideoLoopContainer = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  transform-origin: top left;
  border-radius: calc(var(--r2) / var(--fx)) / calc(var(--r2) / 1);
  transform: ${({ $deviceType }) =>
    $deviceType == 'Online'
      ? 'scale(0.6) translate(calc(calc(var(--phoneWidth) / 7) / var(--fx)), calc(var(--phoneWidth) / 7))'
      : ''};

  transition: all 0.5s var(--transition-timing-function); //TODO
`;

const PrimaryVideo = styled.video`
  height: 105%; //? Helps video fills rounded corners
  min-height: 100%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform-origin: top left;
  transform: scaleX(calc(1 / var(--fx))) translate(-50%, -50%); //TV

  transition: transform 0.5s var(--transition-timing-function);
  transition: none;
`;

const SecondaryDevice = styled.div`
  position: absolute;
  inset: 0;
  z-index: 1;
  background: radial-gradient(
    circle at 0% 15%,
    var(--blue4) 0%,
    var(--blue2) 30%
  );
  border-radius: var(--r);

  transform-origin: bottom center;
  transition: transform 0.5s var(--transition-timing-function);

  &::before {
    --padding2: 1px;
    --r3: calc(var(--r) - var(--padding2));

    position: absolute;
    z-index: -2;

    inset: var(--padding2);
    background: var(--blue2);
    border-radius: var(--r3);
    content: '';
  }

  ${Tooltip} {
    transform: translate(-50%, -70px);
  }

  &:hover {
    ${Tooltip} {
      opacity: 0.9;
      transform: translate(-50%, 0);
    }
  }
`;

const SecondaryDeviceContent = styled.div`
  z-index: -1;
  position: absolute;
  top: var(--screenPadding);
  right: var(--screenPadding);
  bottom: var(--screenPadding);
  left: var(--screenPadding);

  background: var(--white);
  border-radius: var(--r2);
`;

const LeftDevice = styled(SecondaryDevice)`
  transform: ${({ $deviceType }) =>
    $deviceType == 'Mobile'
      ? 'scale(0.92) translateX(-78%) rotateZ(-10deg)'
      : ''};
`;

const RightDevice = styled(SecondaryDevice)`
  transform: ${({ $deviceType }) =>
    $deviceType == 'Mobile'
      ? 'scale(0.92) translateX(78%) rotateZ(10deg)'
      : ''};
`;

const PhoneNotch = styled.div`
  width: 30%;
  aspect-ratio: 5 / 1;
  background: var(--blue2);
  border-radius: 0 0 4px 4px;
  position: absolute;
  top: -1px; //? To prevent aliasing gap
  left: 50%;
  transform: translateX(-50%);

  &::before {
    content: '';
    width: 25%;
    height: 2px;
    border-radius: 4px;
    background: var(--blue1);

    position: absolute;
    top: calc(50% - 1px);
    left: 37%;
  }

  &::after {
    content: '';
    height: 25%;
    aspect-ratio: 1 / 1;
    border-radius: 50%;
    background: var(--blue1);

    position: absolute;
    top: calc(50% - 2px);
    right: 25%;
  }
`;

const PrimaryPhoneNotch = styled(PhoneNotch)`
  transform: ${({ $deviceType }) =>
    $deviceType != 'Mobile'
      ? 'translateX(-50%) translateY(-50px)'
      : 'translateX(-50%)'};
  transition: transform 0.5s var(--transition-timing-function);
  z-index: 2;
`;

const YouTubeOverlay = styled(YouTubeOverlaySVG)`
  position: absolute;
  height: 100%;
  bottom: 0;
  left: 50%;
  transform-origin: top left;
  transform: scaleX(calc(1 / var(--fx))) translateX(-50%);
  opacity: ${({ $deviceType }) => ($deviceType == 'Online' ? 1 : 0)};
  transition: transform 0.5s var(--transition-timing-function);
`;

const InstaStoriesOverlay = styled(InstaStoriesOverlaySVG)`
  z-index: 5;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  transform: ${({ $deviceType }) =>
    $deviceType == 'Mobile' ? '' : 'translateY(25px)'};
  opacity: ${({ $deviceType }) => ($deviceType == 'Mobile' ? 1 : 0)};
  transition: opacity 0.3s var(--transition-timing-function),
    transform 0.5s var(--transition-timing-function);
  pointer-events: none;
`;

const InstaPostOverlay = styled(InstaPostOverlaySVG)`
  position: absolute;
  width: calc(100% + 1px); //Minimises visable aliasing on rotated device
  height: 100%;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
`;

const InstaPostVideoContainer = styled.div`
  position: absolute;
  top: 31%;
  width: calc(100% + 1px); //Minimises visable aliasing on rotated device
  left: 50%;
  transform: translateX(-50%);
  aspect-ratio: 1 / 1;
  overflow: hidden;
`;

const FacebookOverlay = styled(FacebookOverlaySVG)`
  position: absolute;
  width: calc(100% + 1px); //Minimises visable aliasing on rotated device
  height: 100%;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
`;

const FacebookVideoContainer = styled.div`
  position: absolute;
  top: 23%;
  width: calc(100% + 1px); //Minimises visable aliasing on rotated device
  left: 50%;
  transform: translateX(-50%);

  aspect-ratio: 4 / 5;
  overflow: hidden;
`;

const SecondaryVideo = styled.video`
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  height: 100%;
  min-width: 105%;
  min-height: 105%;
`;

const DeviceSwitcher = ({ data }) => {
  const { mp4, webm, poster } = data;
  const [deviceType, setDeviceType] = useState('TV');

  return (
    <SwitcherContainer>
      <DeviceToggle setDeviceType={setDeviceType} deviceType={deviceType} />
      <Spacer size={48} />
      <DeviceContainer>
        <TVFootLeft $deviceType={deviceType} />
        <TVFootRight $deviceType={deviceType} />
        <PrimaryDevice $deviceType={deviceType}>
          <PrimaryContentContainer $deviceType={deviceType}>
            <PrimaryPhoneNotch $deviceType={deviceType} />
            <YouTubeOverlay $deviceType={deviceType} />
            <PrimaryVideoLoopContainer $deviceType={deviceType}>
              <PrimaryVideo
                autoPlay
                muted
                loop
                playsInline
                preload='metadata'
                poster={poster.asset.url}
              >
                <source src={webm.asset.url} type='video/webM' />
                <source src={mp4.asset.url} type='video/mp4' />
              </PrimaryVideo>
            </PrimaryVideoLoopContainer>
          </PrimaryContentContainer>
          <Tooltip $deviceType={deviceType}>
            <ButtonText>{deviceType == 'Mobile' ? '9:16' : '16:9'}</ButtonText>
          </Tooltip>
        </PrimaryDevice>
        <InstaStoriesOverlay $deviceType={deviceType} />
        <LeftDevice $deviceType={deviceType}>
          <SecondaryDeviceContent>
            <PhoneNotch />
            <InstaPostOverlay />
            <InstaPostVideoContainer>
              <SecondaryVideo
                autoPlay
                muted
                loop
                playsInline
                preload='metadata'
                poster={poster.asset.url}
              >
                <source src={webm.asset.url} type='video/webM' />
                <source src={mp4.asset.url} type='video/mp4' />
              </SecondaryVideo>
            </InstaPostVideoContainer>
          </SecondaryDeviceContent>
          <Tooltip $deviceType={deviceType}>
            <ButtonText>1:1</ButtonText>
          </Tooltip>
        </LeftDevice>
        <RightDevice $deviceType={deviceType}>
          <SecondaryDeviceContent>
            <PhoneNotch />
            <FacebookOverlay />
            <FacebookVideoContainer>
              <SecondaryVideo
                autoPlay
                muted
                loop
                playsInline
                preload='metadata'
                poster={poster.asset.url}
              >
                <source src={webm.asset.url} type='video/webM' />
                <source src={mp4.asset.url} type='video/mp4' />
              </SecondaryVideo>
            </FacebookVideoContainer>
          </SecondaryDeviceContent>
          <Tooltip $deviceType={deviceType}>
            <ButtonText>4:5</ButtonText>
          </Tooltip>
        </RightDevice>
      </DeviceContainer>
    </SwitcherContainer>
  );
};

export default DeviceSwitcher;
