import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import FiveStars from '../images/google-review/stars-rd.inline.svg';
import GoogleLogo from '../images/google-review/google-logo.png';

const GoogleReviewBadge = styled.div`
  display: flex;
  flex-direction: column;
`;

const FiveStarsImg = styled(FiveStars)`
  width: 6.5em;
  height: auto;
  margin-bottom: ${({ theme }) => theme.spacing.xs};
  fill: ${({ theme }) => theme.color.accent};
`;

const Row = styled.div`
  display: flex;
  align-items: center;
`;

const GoogleLogoImg = styled.img`
  width: 1.5em;
  height: auto;
  margin-right: 6px;
`;

const GoogleRating = styled.p`
  font-family: Poppins, sans-serif;
  color: ${(props) => (props.light ? 'white' : '#08273A')};
  font-size: ${({ theme }) => theme.fontSize.xxxs};
`;

const GoogleBadge = ({ light, ...otherProps }) => (
  <GoogleReviewBadge {...otherProps}>
    <FiveStarsImg />
    <Row>
      <GoogleLogoImg src={GoogleLogo} />
      <GoogleRating light={light}>(5/5 Rating)</GoogleRating>
    </Row>
  </GoogleReviewBadge>
);

export default GoogleBadge;

GoogleBadge.propTypes = {
  light: PropTypes.bool,
};
