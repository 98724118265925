import React, { useState, useCallback, useEffect } from 'react';
import styled from 'styled-components';

//Component Imports
import { HeroPreHeader, SuperHeader, P1 } from '../theme/typography';
import Button from './buttons';
import GoogleBadge from '../components/googleBadge'; //TODO: Reimplement
import VideoModal from './videoModal';

//Styled Components
const TextBlock = styled.div`
  color: var(--primary-text);
  max-width: 700px;
`;

const SecondaryHeader = styled(HeroPreHeader)`
  margin-bottom: 4px;
  color: var(--secondary-text);
`;

const PrimaryHeader = styled(SuperHeader)`
  margin-bottom: 24px;
  text-transform: capitalize;
  color: var(--primary-text);
`;

const BodyText = styled(P1)`
  margin-bottom: ${({ hasButtons }) => (hasButtons ? '48px' : '0')};
  color: var(--primary-text);
  padding-right: 32px;
  @media (min-width: 500px) {
    padding-right: 64px;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 24px;
  flex-direction: column;
  margin-bottom: none;
  text-align: center;

  @media (min-width: 500px) {
    flex-direction: row;
    text-align: left;
  }
`;

const SuperHeaderBlock = ({ data, onReelOpen, onReelClose }) => {
  const {
    preHeader,
    title,
    body,
    primaryCTA,
    primaryCTAData,
    secondaryCTA,
    secondaryCTAData,
  } = data;

  const [isModalOpen, setIsModalOpen] = useState(false);

  console.log(secondaryCTAData);

  const toggleModal = useCallback(
    () => setIsModalOpen((prevState) => !prevState),
    [],
  );

  useEffect(() => {
    if (isModalOpen) {
      onReelOpen();
    } else {
      onReelClose();
    }
  }, [isModalOpen]);

  return (
    <TextBlock>
      <SecondaryHeader>{preHeader}</SecondaryHeader>
      <PrimaryHeader>{title}</PrimaryHeader>
      <BodyText hasButtons={primaryCTA || secondaryCTA}>{body}</BodyText>
      <ButtonContainer>
        {primaryCTA && (
          <Button
            label={primaryCTAData.label}
            type={primaryCTAData.type ? primaryCTAData.type : 'primary'}
            arrow={primaryCTAData.arrow ? true : false}
            path={primaryCTAData.path ? primaryCTAData.path : ''}
          />
        )}
        {secondaryCTA && !secondaryCTAData.videoModal && (
          <Button
            label={secondaryCTAData.label}
            type={secondaryCTAData.type ? secondaryCTAData.type : 'link'}
            arrow={secondaryCTAData.arrow ? true : false}
            path={secondaryCTAData.path ? secondaryCTAData.path : ''}
          />
        )}
        {secondaryCTA && secondaryCTAData.videoModal && (
          <>
            <Button
              label={secondaryCTAData.label}
              type={secondaryCTAData.type ? secondaryCTAData.type : 'link'}
              arrow={secondaryCTAData.arrow ? true : false}
              onClick={toggleModal}
            />
            <VideoModal
              video={secondaryCTAData.video}
              isModalOpen={isModalOpen}
              onClose={() => setIsModalOpen(false)}
            />
          </>
        )}
      </ButtonContainer>
    </TextBlock>
  );
};

export default SuperHeaderBlock;
