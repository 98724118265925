import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import { GatsbyImage } from 'gatsby-plugin-image';

// Component Imports
import Container from './container';
import { D3, Quote, ButtonText, Technical } from '../theme/typography';

// Styled Components
const TestimonialTile = styled.div`
  --testimonial-tile-height: 600px;
  --testimonial-border-radius: 5px;

  display: flex;
  flex-direction: column;

  margin: 0 auto;
  border-radius: var(--testimonial-border-radius);
  background: ${({ theme }) => theme.color.white};
  box-shadow: ${({ theme }) => theme.boxShadow.coloured};
  height: var(--testimonial-tile-height);
  max-width: 340px;

  @media (min-width: 600px) {
    --testimonial-tile-height: 360px;
    flex-direction: row;
    max-width: 1000px;
    /* width: 90%; */
  }
`;

const ImageContainer = styled.div`
  flex: 1;
  flex-shrink: 1;
  min-height: 0;
  background-size: cover;
  background-repeat: no-repeat;
  clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 70%);

  @media (min-width: 37.5em) {
    clip-path: polygon(0% 0%, 100% 0%, 70% 100%, 0% 100%);
  }
`;

const BackgroundImage = styled(GatsbyImage)`
  width: 100%;
  height: 100%;
  object-fit: cover;

  /* Need these border radius to prevent corners appearing during image load */
  border-radius: var(--testimonial-border-radius)
    var(--testimonial-border-radius) 0 0;
  @media (min-width: 37.5em) {
    border-radius: var(--testimonial-border-radius) 0 0
      var(--testimonial-border-radius);
  }
`;

const QuoteContainer = styled.div`
  flex: 2;
  text-align: left;
  padding: 32px 32px 32px 32px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (min-width: 800px) {
    padding-right: 64px;
  }
`;

const QuoteHighlight = styled(D3)`
  color: ${({ theme }) => theme.color.blue1};
  margin-bottom: 24px;

  @media (min-width: 600px) {
    margin-bottom: 16px;
  }
`;

const QuoteBody = styled(Quote)`
  color: ${({ theme }) => theme.color.blue2};
  margin-bottom: 32px;
  > span {
    font-weight: ${({ theme }) => theme.fontWeight.semibold};
  }

  @media (min-width: 600px) {
    margin-bottom: 24px;
  }
`;

const ClientContainer = styled.div`
  display: flex;
  flex-direction: column;
  color: ${({ theme }) => theme.color.blue3};
`;

const Headshot = styled(GatsbyImage)`
  border-radius: 50%;
  width: 48px;
  height: 48px;
  margin-right: 16px;
`;

const ClientName = styled(ButtonText)`
  display: flex;
  flex-direction: column;

  @media (min-width: 500px) {
    display: unset;
  }
`;

const JobTitle = styled(Technical)`
  text-transform: none;
`;

const TestimonialSlide = ({ testimonial }) => (
  // Container per slide to allow swipe to hit edge of screen
  <Container>
    <TestimonialTile>
      <ImageContainer>
        <BackgroundImage
          image={testimonial.image.asset.gatsbyImageData}
          alt='Project Photo'
        />
      </ImageContainer>
      <QuoteContainer>
        <QuoteHighlight>{testimonial.quoteHighlight}</QuoteHighlight>
        <QuoteBody>{testimonial.quote}</QuoteBody>
        <ClientContainer>
          {/* // TODO: Fix headshot */}
          {/* {testimonial.image.asset.gatsbyImageData && (
            <Headshot
              image={testimonial.image.asset.gatsbyImageData}
              alt='Client Headshot'
            />
          )} */}
          <ClientName>{testimonial.author}</ClientName>
          <JobTitle as='span'>{`${testimonial.jobTitle}, ${testimonial.client.company}`}</JobTitle>
        </ClientContainer>
      </QuoteContainer>
    </TestimonialTile>
  </Container>
);

TestimonialSlide.propTypes = {
  testimonial: propTypes.object,
};

export default TestimonialSlide;
