import React from 'react';
import styled from 'styled-components';

//Component Imports
import AnimatedStripe from '../components/animatedStripe';

//Asset Imports
import Stripes02SVG from '../images/stripes/stripes02.inline.svg';
import Stripes03SVG from '../images/stripes/stripes03.inline.svg';
import Stripes05SVG from '../images/stripes/stripes05.inline.svg';
import Stripes06SVG from '../images/stripes/stripes06.inline.svg';

const SectionVideoContainer = styled.div`
  z-index: 1;
  position: relative;

  margin: 0 auto;
  width: 100%;
  max-width: 600px;

  padding-top: 56px;

  @media (min-width: 1024px) {
    padding-top: 0;
    padding-right: ${({ position }) => (position == 'left' ? '104px' : '0')};
    padding-left: ${({ position }) => (position == 'right' ? '104px' : '0')};
    width: 50vw;
    max-width: none;
  }
`;

const VideoStripes01 = styled(AnimatedStripe)`
  position: absolute;
  top: -12%;
  right: -18%;
  width: 70%;
  height: 100%;
  z-index: -1;
  pointer-events: none;

  @media (min-width: 1024px) {
    top: -32%;
    right: -8%;
  }
`;

const VideoStripes02 = styled(AnimatedStripe)`
  position: absolute;
  bottom: -20%;
  left: -10%;
  width: 30%;
  height: 60%;
  pointer-events: none;
`;

const VideoStripes03 = styled(AnimatedStripe)`
  position: absolute;
  top: -15%;
  left: 3%;
  width: 35%;
  height: 70%;
  z-index: -1;
  pointer-events: none;
`;

const VideoStripes04 = styled(AnimatedStripe)`
  position: absolute;
  bottom: -30%;
  right: -10%;
  width: 55%;
  height: 75%;
  pointer-events: none;
`;

const SectionVideo = styled.video`
  max-width: 100%;
  display: block;
  border-radius: 8px;

  @media (min-width: 1024px) {
    border-radius: 0 8px 8px 0;
    border-radius: ${({ position }) =>
      position == 'left' ? '0 8px 8px 0' : '8px 0 0 8px'};
  }
`;

const HalfScreenVideo = ({ data, position }) => {
  const { mp4, webm, poster } = data;
  return (
    <SectionVideoContainer position={position}>
      {position == 'left' && (
        <VideoStripes01 direction={'left'}>
          <Stripes02SVG />
        </VideoStripes01>
      )}
      {position == 'right' && (
        <VideoStripes03 direction={'right'}>
          <Stripes06SVG />
        </VideoStripes03>
      )}
      <SectionVideo
        autoPlay
        muted
        loop
        playsInline
        preload='metadata'
        poster={poster.asset.url}
        position={position}
      >
        <source src={webm.asset.url} type='video/webm' />
        <source src={mp4.asset.url} type='video/mp4' />
      </SectionVideo>
      {position == 'left' && (
        <VideoStripes02 direction={'left'}>
          <Stripes03SVG />
        </VideoStripes02>
      )}
      {position == 'right' && (
        <VideoStripes04 direction={'right'}>
          <Stripes05SVG />
        </VideoStripes04>
      )}
    </SectionVideoContainer>
  );
};

export default HalfScreenVideo;
