import React from 'react';
import styled from 'styled-components';

const Center = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: clip;
`;

const ClientLogos = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 24px 32px;
  justify-content: center;

  @media (min-width: 1200px) {
    gap: 48px 64px;
  }
`;

const AspectRatioWrapper = styled.div`
  position: relative;
  // Set fluid width from 375vw -> 1440vw
  width: clamp(85.33px, 4.01vw + 70.31px, 128px);
  aspect-ratio: 16 / 9;
`;

const AspectRatioContents = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;

const ClientLogo = styled.svg`
  max-width: 100%;
  max-height: 100%;
  fill: var(--logo-fill, var(--blue3));
`;

const LogoGrid = ({ clients }) => {
  return (
    <Center>
      <ClientLogos>
        {clients.map((client, index) => (
          <AspectRatioWrapper
            key={client.company ? client.company : client.awardName}
          >
            <AspectRatioContents>
              {client.svgLogo != undefined ? (
                <ClientLogo
                  dangerouslySetInnerHTML={{ __html: client.svgLogo }}
                />
              ) : (
                <div>{`Logo Not Found:${client.company} `}</div>
              )}
            </AspectRatioContents>
          </AspectRatioWrapper>
        ))}
      </ClientLogos>
    </Center>
  );
};

export default LogoGrid;
